.container {
  margin-right: 16px;
  margin-bottom: 16px;
  display: inline-block;
}

.prefix {
  margin-right: 8px;
}

.postfix {
  margin-left: 8px;
}

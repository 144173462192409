.container {
  display: flex;
  flex-direction: column;
  min-width: 360px;
}

.large-container {
  padding: 12px 0 0;
  min-height: 483px;
}

.middle-container {
  padding: 0;
  min-height: 0;
}

@media (max-width: 640px) {
  .container {
    min-width: 100%;
  }
}

@media (max-width: 640px) {
  .middle-container {
    padding-top: 16px;
  }
}

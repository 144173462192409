.button {
  padding: 2px 12px;
  cursor: pointer;
  background: none;
  border: 0;
}

.button--left {
  margin-left: -12px;
}

.button--right {
  margin-right: -12px;
}

.container {
  margin-top: 12px;
  padding: 0 16px;
}

.icon {
  margin-right: 4px;
  display: inline-block;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons {
  margin-top: 12px;
}

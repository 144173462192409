.container {
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  max-width: 100%;
  background: var(--gray6_100);
  border-radius: 4px;
}

.container:last-of-type {
  margin-right: 0;
}

.container:hover {
  background-color: var(--gray10_100);
}

.container:focus {
  box-shadow: 0 0 0 1px var(--stroke-control-focused);
}

.container > span {
  padding: 8px 0 8px 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 0;
  max-width: 100%;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--decorative-theme-dark);
}

.button {
  position: relative;
  padding: 0;
  padding-right: 4px;
  display: flex;
  flex: 0 0 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: 0;
}

.button {
  padding: 0 16px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: var(--decorative-theme-dark);
  background: none;
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
}

.button:hover {
  background-color: var(--black_10);
}

.button:active {
  background-color: var(--black_20);
}

.button:focus {
  box-shadow: 0 0 1px 1px var(--stroke-control-focused);
}

@media (min-width: 641px) {
  .button {
    padding: 0 12px;
  }
}

.icon_wrapper {
  width: 16px;
  height: 15px;
}

.button_text {
  margin-left: 8px;
  font-size: 16px;
  line-height: 38px;
  color: var(--decorative-theme-dark);
}

.button_text_empty {
  color: var(--gray40_100);
}

.container {
  display: flex;
}

.travel-time {
  margin-right: 8px;
  width: 100%;
  min-width: 100px;
}

.travel-type {
  flex-shrink: 0;
}

.adornment {
  padding: 0 12px;
}

.travel-type--icon {
  display: none;
}

.icon-container {
  display: flex;
  height: 22px;
  align-items: center;
}

@media (max-width: 360px) {
  .travel-type--text {
    display: none;
  }

  .travel-type--icon {
    display: block;
  }
}

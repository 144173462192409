.container {
  --caption: '';
}

.container label {
  display: flex;
  justify-content: space-between;
}

.container--top-aligned label {
  align-items: start;
}

.container--padded {
  padding: var(--unit_3) 0;
}

.container span:first-of-type {
  order: 2;
  flex-shrink: 0;
}

.container span:last-of-type {
  order: 1;
  margin-right: var(--unit_3);
  margin-left: 0;
  display: flex;
  flex-direction: column;
}

.container--top-aligned span:last-of-type {
  font-weight: bold;

  &::after {
    margin-top: var(--unit_1);
    font-weight: normal;
  }
}

/* хак, чтобы добавить кэпшен под лейбл свитча */
.container--with-caption span:last-of-type::after {
  font-size: 14px;
  line-height: 20px;
  content: var(--caption);
  color: var(--text-secondary-default);
}

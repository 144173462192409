.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media (min-width: 641px) {
  .content {
    padding: 16px 24px;
  }
}

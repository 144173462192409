.container {
  padding: 40px;
}

.title {
  margin-bottom: 24px;
  text-align: center;
}

.links {
  margin-right: -16px;
  margin-bottom: -16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.link {
  margin-right: 16px;
  margin-bottom: 16px;
  flex: 0 0 auto;
  display: block;
  width: 134px;
  height: 40px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: transparent;
  background-repeat: no-repeat;
}

.link--app-gallery {
  background-image: url('./assets/appGallery.svg');
}

.link--app-store {
  background-image: url('./assets/appStore.svg');
}

.link--google-play {
  background-image: url('./assets/googlePlay.svg');
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  width: 100%;
  max-width: 556px;
  pointer-events: auto;
  background: white;
  border: 1px solid #ced1d7;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.container--error {
  background: linear-gradient(0deg, rgba(255, 31, 52, 0.1), rgba(255, 31, 52, 0.1)), white;
  border-color: rgba(255, 31, 52, 0.4);
}

.container--success {
  background: linear-gradient(0deg, rgba(58, 197, 0, 0.1), rgba(58, 197, 0, 0.1)), white;
  border-color: rgba(58, 197, 0, 0.4);
}

.container--warning {
  background: linear-gradient(0deg, rgba(255, 126, 0, 0.1), rgba(255, 126, 0, 0.1)), white;
  border-color: rgba(255, 126, 0, 0.4);
}

.container + .container {
  margin-top: 16px;
}

.image {
  margin-right: 12px;
}

.content {
  flex: 1;
}

.heading {
  margin-bottom: 8px;
}

.close {
  margin-left: 12px;
  padding: 4px;
  color: #ced1d7;
  background: none;
  border: none;
}

.container--error .close {
  color: rgba(255, 31, 52, 0.4);
}

.container--success .close {
  color: rgba(58, 197, 0, 0.4);
}

.container--warning .close {
  color: rgba(255, 126, 0, 0.4);
}

.top-content {
  padding-block-end: var(--unit_4);
  display: flex;
  align-items: center;
  gap: var(--unit_2);
}

.bottom-content {
  padding-block-start: var(--unit_2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary-default);
}

.toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
}

.container {
  margin-bottom: 16px;
  padding: 12px 16px;
  padding-left: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  text-decoration: none;
  background-color: var(--gray6_100);
  background-image: url('./assets/commercial.svg');
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 40px;
}

@media (min-width: 641px) {
  .container {
    border-radius: 4px;
  }
}

.container {
  position: sticky;
  z-index: 10;
  bottom: 0;
  padding: 12px 16px;
  display: flex;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--gray10_100);
}

.action:last-child {
  width: 100%;
}

.action + .action {
  margin-left: 8px;
}

@media (max-width: 360px) {
  /* До текста в кнопке из ui-kit стилями никак не достучаться, поэтому такой селектор */
  .action > button[data-name='map-search-button'] > span {
    display: none;
  }
}

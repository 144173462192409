.container {
  display: flex;
  flex-direction: column;
}

.search {
  position: sticky;
  top: 54px;
  padding: 24px 16px 16px;
  background-color: white;
}

.label {
  margin-bottom: 8px;
  padding: 0 16px;
}

.container {
  position: sticky;
  bottom: 0;
  padding: 12px 16px;
  display: flex;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--gray10_100);
}

.action {
  width: 100%;
}

.action + .action {
  margin-left: 8px;
}

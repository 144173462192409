.button {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_22px);
  text-align: left;
  cursor: pointer;
  color: var(--decorative-theme-dark);
  background: none;
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
}

.button:hover {
  background-color: var(--black_10);
}

.button:active {
  background-color: var(--black_20);
}

.button:focus {
  box-shadow: 0 0 1px 1px var(--stroke-control-focused);
}

@media (min-width: 641px) {
  .button {
    padding: 3px 12px;
    font-size: var(--fontSize_14px);
    line-height: var(--lineHeight_20px);
  }
}
